<template>
<div class="flex flex-col bg-white rounded-2xl shadow-xl mt-8 mb-4">
	<div class="flex-1 relative pt-16 px-6 pb-8 md:px-8">
	  <div class="absolute top-0 p-2 inline-block bg-accent rounded-xl shadow-lg transform -translate-y-1/2">
		<img style="width: 50px; "
        v-if="imageUrl"
        class="object-cover"
        :src="imageUrl"
        alt=""
      />
	  </div>
	  <p class="text-lg font-semibold text-gray-900 capitalize">
        <slot> </slot>
      </p>
	  <p class="mt-4 text-base text-gray-500">
		{{ summary }}
		</p>
	</div>
	<div class="p-6 bg-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8">
	<router-link
        v-if="href"
        :to="href"
        class="text-base text-accent font-medium text-white hover:text-opacity-80"
        >Learn More<span aria-hidden="true" class="inline-block ml-2	"> →</span></router-link
      >
	</div>
</div>
		 
</template>

<script>
export default {
  props: {
    imageUrl: {
      required: false,
      type: String,
    },
    summary: {
      required: false,
      type: String,
    },
    href: {
      required: false,
      type: String,
    },
  },
};
</script>
